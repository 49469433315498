<template>
  <div
    class="bonus-timer"
    :class="{ mobile: isMobile, 'size-small': size === 's' }"
  >
    <span v-if="label" class="timer-title" data-t="timer-tilte"
      >{{ label }}
    </span>
    <span v-if="expiredBeforeNow || isTimerFinished">
      {{ t('bonuses.timeExpired') }}
    </span>
    <span v-else class="timer-countdown" data-t="timer-countdown">
      <StIcon
        v-if="isPaused"
        class="timer-pause"
        name="pause-bold-solid"
        size="20"
      />
      <template v-if="days > 0">
        <span v-if="isShownDaysLabel" class="time-part">
          {{ t('bonuses.days', days) }}
        </span>
        <span v-else class="time-part">{{ days }}</span>
        <span class="colon">:</span>
      </template>
      <span class="time-part">{{ hours }}</span>
      <span class="colon">:</span>
      <span class="time-part">{{ minutes }}</span>
      <span class="colon">:</span>
      <span class="time-part">{{ seconds }}</span>
    </span>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    expiresAt: string
    isPaused?: boolean
    label?: string
    isShownDaysLabel?: boolean
    isMobile?: boolean
    size?: 's' | 'm'
  }>(),
  {
    size: 'm',
  },
)

const { t } = useI18n()

const { parseDate, duration: parseDuration } = useDate()

const expiredBeforeNow = computed(() =>
  parseDate(props.expiresAt).isBefore(parseDate()),
)

const currentTimestamp = useTimestamp({ interval: 1000 })
const initialTimestamp = ref(currentTimestamp.value)

watch(
  () => props.isPaused,
  () => {
    initialTimestamp.value = currentTimestamp.value
  },
)

const countdown = computed(() => {
  const timestamp = props.isPaused
    ? initialTimestamp.value
    : currentTimestamp.value
  const duration = parseDuration(
    parseDate(props.expiresAt).diff(parseDate(timestamp)),
  )

  return duration
})

const days = computed(() => Math.floor(countdown.value.asDays()))

const hours = computed(() =>
  countdown.value.hours().toString().padStart(2, '0'),
)
const minutes = computed(() =>
  countdown.value.minutes().toString().padStart(2, '0'),
)
const seconds = computed(() =>
  countdown.value.seconds().toString().padStart(2, '0'),
)

const isTimerFinished = ref(false)

watch(
  () => seconds.value,
  () => {
    if (parseDate(props.expiresAt).isBefore(parseDate())) {
      isTimerFinished.value = true
    }
  },
)
</script>

<style scoped>
.bonus-timer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-025);
  align-items: center;

  .timer-title {
    font: var(--desktop-text-xs-medium);
    color: var(--text-tertiary);
  }
  /* stylelint-disable */
  .timer-countdown {
    display: inline-flex;
    align-items: center;

    font-family: 'SF Pro Display';
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
  }
  /* stylelint-enable */

  .colon {
    display: flex;
    justify-content: center;

    width: 12px;
    margin-bottom: var(--spacing-025);

    color: var(--icon-tertiary);
  }

  .timer-pause {
    margin-right: var(--spacing-050);
  }

  &.size-small {
    .timer-countdown {
      min-width: 62px;
      height: 16px;

      font: var(--desktop-text-xs-semibold);
      color: var(--text-primary);
      text-align: center;
    }

    .colon {
      width: 8px;
    }
  }

  &.mobile {
    .timer-title {
      font: var(--mobile-caption-1-regular);
    }

    .timer-countdown {
      font: var(--mobile-headline-semibold);
    }

    &.size-small {
      .colon {
        width: 6px;
      }

      .timer-countdown {
        font: var(--desktop-text-xs-medium);
      }
    }
  }
}
</style>
